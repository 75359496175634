import getAreaOfPolygon from 'geolib/es/getAreaOfPolygon';
import convertArea from 'geolib/es/convertArea';
import getPathLenght from 'geolib/es/getPathLength';
import { MapPointInterface } from '../../shared/layout/fap_main-map/data/map-point.interface';


export class MapHelper {
    public static readonly GOOGLE_API_KEY: string = 'AIzaSyAQfZDRPxB6kQBKRoEzFvYM58n8bmYFhfs&libraries=places';

    public static getPolygonLength(coordinates: Array<any>): number {
        return getPathLenght(coordinates);
    }

    public static getPolygonArea(coordinates: Array<any>, unit: string): number {
        return convertArea(getAreaOfPolygon(coordinates), unit);
    }

    // convert AGM polygon coords to API accepted polygon coords
    public static convertToAPIPolygon(polygon: Array<MapPointInterface>): Array<Array<number>> {
        const convertedPolygon: Array<Array<number>> = polygon.map((point: MapPointInterface) => [point.lat, point.lng]);
        convertedPolygon.push(convertedPolygon[0]); // close the polygon with the starting point
        return convertedPolygon;
    }

    // convert API polygon coords to AGM coords
    public static convertToAGMPolygon(polygon: Array<Array<number>>): Array<MapPointInterface> {
        const convertedPolygon: Array<MapPointInterface> = polygon.map((point: Array<number>) => ({lng: point[1], lat: point[0]}));
        convertedPolygon.pop(); // remove the closing point (AGM doesn't need it)
        return convertedPolygon;
    }
}
