<!-- <ng-container *ngFor="let pool of pools">
    <pool-card [pool]="pool">
    </pool-card>
</ng-container> -->
<fap-header>
    <div class="add_btn mr-3">
        <fap-round-button [backgroundColor]="'tertiary'" (clicked)="getFormTypes()"></fap-round-button>
    </div>
</fap-header>
<div id="parent" class="d-flex mbl_wrap">
    <ng-container *ngIf="pools.length">
        <div class="mr-2 mb-2 pool-card"*ngFor="let pool of pools; let index = index;">
            <fap-pool-widget
                *ngIf="pool.type === 'pool'"
                (emitShow)="showError()"
                (emitDelete)="deletePool($event)"
                (emitFormUpdate)="getWidgets()"
                [widget]="pool"
                [objects]="objects"
                [sensors]="sensors"
                [unitTypes]="unitTypes"
                [devices]="devices"
                [editValue]="true"
                [index]="index"
                style="display: block; width: 100%;"
            ></fap-pool-widget>
        </div>
    </ng-container>

</div>

<fap-modal #addEditPoolModal [inputTitle]="entityInfo?.name_t?.[langString] ? entityInfo?.name_t?.[langString] : entityInfo?.name"  [inputIsLarge]="true" [showFooter]="false" (outputOnHide)="resetObjectId()">
    <div class="minus_margin" *ngIf="entityInfo">
    <fap-attributes
                [entityRelation]="'pool'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="objectId"
                (closeModal)="hidePoolsModal(); redirectBack()"
                (formUpdate)="onUpdateAction();"
                [isDynamic]="true">
            </fap-attributes>
        </div>
</fap-modal>